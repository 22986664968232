import React, { forwardRef, useImperativeHandle } from 'react';
import { initProps, composeValues } from '../Helpers/ComponentHelper';

const BaseComponent = forwardRef((props, ref) => {
  const propMap = {
    color: "black",
    size: "md",
    className: "",
  }
  props = initProps(props, propMap);

  const {
    children,
  } = props;

  function paletteClass(props) {
    // console.log(props.palette)
    switch (props.color) {
      case "black": return "black-palette";
      case "white": return "white-palette";
      case "primary": return "primary-palette";
      case "secondary": return "secondary-palette";
      default: return "black-palette";
    }
  }

  function sizeClass(props) {
    switch (props.size) {
      case "xs": return "xs-size";
      case "sm": return "sm-size";
      case "md": return "md-size";
      case "lg": return "lg-size";
      case "xl": return "xl-size";
      case "xl2": return "xl2-size";
      case "xl3": return "xl3-size";
      default: return "md-size";
    }
  }

  useImperativeHandle(ref, () => ({
    ...composeValues({
      paletteClass,
      sizeClass,
    }, propMap),
    propMap
  }));

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
})

export { BaseComponent };
