import React, { useState } from "react";

import { IonFab, IonFabButton, IonFabList } from '@ionic/react';

import { saveEmail } from "@den/common/src/Helpers/AWS";

import { ButtonComponent } from "@den/common/src/Components/Button/ButtonComponent";
import { IconComponent } from "@den/common/src/Components/Icon/IconComponent";
import { IconButtonComponent } from "@den/common/src/Components/IconButton/IconButtonComponent";
import { InputComponent } from "@den/common/src/Components/Input/InputComponent";
import Carousel from "../../Components/Carousel/Carousel";
import HoverImage from "../../Components/HoverImage/HoverImage";

import appstore_apple from "@den/common/src/Assets/Img/appstore-apple.png";
import appstore_google from "@den/common/src/Assets/Img/appstore-google.png";
import carousel5 from "@den/common/src/Assets/Img/Asset-10@4x.png";
import carousel1 from "@den/common/src/Assets/Img/Asset-6@4x.png";
import carousel2 from "@den/common/src/Assets/Img/Asset-7@4x.png";
import carousel3 from "@den/common/src/Assets/Img/Asset-8@4x.png";
import carousel4 from "@den/common/src/Assets/Img/Asset-9@4x.png";
import devices from "@den/common/src/Assets/Img/den-devices.png";
import splash_network from "@den/common/src/Assets/Img/den-network.svg";
import phone_diag from "@den/common/src/Assets/Img/iphone-diag.png";
import phone_side from "@den/common/src/Assets/Img/iphone-side.png";
import splash_bars from "@den/common/src/Assets/Img/splash-bars.png";
import splash_events from "@den/common/src/Assets/Img/splash-events.png";

import splash from "@den/common/src/Assets/Img/splash-shape3.svg";
import den_full_logo from "@den/common/src/Assets/Logo/Full/full-color-dark.svg";
import den_logo from "@den/common/src/Assets/Logo/Icon/light.svg";

import "./App.scoped.scss";


const App = () => {

  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  async function handleSubmitForm() {
    let isValid = true;
    if (!email) {
      setEmailErrors("Email address is required.")
      isValid = false;
    }

    if (isValid) {
      setIsSubmitting(true);
      try {
        await saveEmail(email);
        setIsSubmitted(true);
      } catch (err) {
        console.error(err);
        setEmailErrors("Submission failed. Network may be down temporarily.")
      }
      setIsSubmitting(false);
    }
  }

  function goToAnchor(e, anchor) {
    e.preventDefault();
    e.stopPropagation();
    const loc = document.location.toString().split('#')[0];
    document.location = `${loc}#${anchor}`;
  }

  return (
    <div className="root">
      <header>
        <div className="header-left">
          <img className="header-logo" src={den_full_logo} alt="Den"></img>
        </div>
        <div className="header-right">
          {/* Only visible on large/PC screens */}
          <div className="header-nav-large">
            <ButtonComponent color="black" color-pattern="flat" size="md" onClick={(evt) => goToAnchor(evt, "home")}>Home</ButtonComponent>
            <ButtonComponent color="black" color-pattern="flat" size="md" onClick={(evt) => goToAnchor(evt, "users")}>Users</ButtonComponent>
            <ButtonComponent color="black" color-pattern="flat" size="md" onClick={(evt) => goToAnchor(evt, "partners")}>Partners</ButtonComponent>
            <ButtonComponent color="black" color-pattern="flat" size="md" onClick={(evt) => goToAnchor(evt, "businesses")}>Businesses</ButtonComponent>
            <ButtonComponent color="primary" color-pattern="flat" size="md" onClick={(evt) => goToAnchor(evt, "lets-go")}>Get Started</ButtonComponent>
          </div>
          {/* Only visible on small/phone/tablet screens */}
          <div className="header-nav-small">
            <IonFab>
              <IonFabButton size="small">
                {/* Icon doesn't seem to render unless this empty div is here */}
                <div style={{background: "white"}}></div>
                <IconComponent size="md" value="details"></IconComponent>
              </IonFabButton>
              <IonFabList side="bottom" style={{ marginTop: '5.2rem' }}>
                <IonFabButton onClick={(evt) => goToAnchor(evt, "home")}>
                  <IconComponent size="md" value="home"></IconComponent>
                </IonFabButton>
                <IonFabButton onClick={(evt) => goToAnchor(evt, "users")}>
                  <IconComponent size="md" value="users"></IconComponent>
                </IonFabButton>
                <IonFabButton onClick={(evt) => goToAnchor(evt, "partners")}>
                  <IconComponent size="md" value="partners"></IconComponent>
                </IonFabButton>
                <IonFabButton onClick={(evt) => goToAnchor(evt, "businesses")}>
                  <IconComponent size="md" value="businesses"></IconComponent>
                </IonFabButton>
              </IonFabList>
            </IonFab>
          </div>
        </div>
      </header>
      <div className="main-body">
        <section id="home" className="splash-section-1">
          <div className="row">
            <div className="col-sm-12 col-md-6 section-text">
              <div className="text-1">
                Storage, for the modern world.
              </div>
              <div className="text-2">
                Den is a network of partners &amp; businesses that you can use to store anything, anywhere.
              </div>
              <div className="row section-buttons">
                <div className="col-sm-12 col-md-auto">
                  <ButtonComponent color="black" color-pattern="flat" size="lg" onClick={(evt) => goToAnchor(evt, "users")}>Try the App</ButtonComponent>
                </div>
                <div className="col-sm-12 col-md-auto">
                  <ButtonComponent color="primary" color-pattern="flat-border" size="lg" onClick={(evt) => goToAnchor(evt, "lets-go")}>Become a Partner</ButtonComponent>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 section-splash-image" style={{ backgroundImage: `url(${splash})` }}>
              <img src={phone_diag} alt=""></img>
            </div>
          </div>
        </section>
        <section id="banner" className="splash-section-2">
          <div className="row no-gutters">
            <div className="col-xs-12 col-sm-8 section-2-cards">
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="card-icon">
                    <IconComponent value="users" size="xl3"></IconComponent>
                  </div>
                  <div className="text-3">
                    For Users
                  </div>
                  <div className="text-4">
                    Item storage, by the hour.
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="card-icon">
                    <IconComponent value="partners" size="xl3"></IconComponent>
                  </div>
                  <div className="text-3">
                    For Partners
                  </div>
                  <div className="text-4">
                    Monetize your extra space.
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="card-icon">
                    <IconComponent value="businesses" size="xl3"></IconComponent>
                  </div>
                  <div className="text-3">
                    For Businesses
                  </div>
                  <div className="text-4">
                    A new way to warehouse.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 section-2-carousel">
              <Carousel>
                <img src={carousel1} alt=""></img>
                <img src={carousel2} alt=""></img>
                <img src={carousel3} alt=""></img>
                <img src={carousel4} alt=""></img>
                <img src={carousel5} alt=""></img>
              </Carousel>
            </div>
          </div>
        </section>
        <section id="users" className="splash-section-3">
          <div className="row">
            <div className="col-xs-12 col-sm-6 section-text">
              <div className="text-5">
                Make today a little lighter.
              </div>
              <div className="text-6">
                You’ve got a city to see, don’t let your belongings weigh you down. Our network of local businesses will keep your items safe while you do.
              </div>
              <div className="section-buttons">
                {/* TODO: Add actual appstore links here */}
                <a href="https://den.nyc" target="_blank" rel="noreferrer">
                  <img src={appstore_apple} alt="Download on the Apple App Store"></img>
                </a>
                <a href="https://den.nyc" target="_blank" rel="noreferrer">
                  <img src={appstore_google} alt="Get it on Google Play"></img>
                </a>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 section-splash-image">
              <img src={phone_side} alt=""></img>
            </div>
          </div>
        </section>
        {/* <section className="splash-section-4">
          <div className="row no-gutters">
            <div className="col">
              <HoverImage heading="Events" src={splash_events}>
                Upgrade your party or event experience with Den and offer your guests the red carpet treatment they deserve.
              </HoverImage>
            </div>
            <div className="col">
              <HoverImage heading="Bars" src={splash_bars}>
                Whether it's coats in the winter, backpacks in the summer, or umbrellas in the rain. Den will not only offer your existing customers a better bar experience, but will give you the chance to engage with new ones.
              </HoverImage>
            </div>
          </div>
        </section> */}
        {/* <section className="splash-section-5">
          <div className="row">
            <div className="col">
              <div className="text-7">
                Pricing
              </div>
              <div className="text-8">
                No setup, implementation fees, or long-term contracts.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="text-9">
                15%
              </div>
              <div className="text-10">
                Per transaction
              </div>
            </div>
            <div className="col-auto divider-column">
            </div>
            <div className="col">
              <div className="text-9">
                $0 Setup
              </div>
              <div className="text-10">
                To get started
              </div>
            </div>
          </div>
        </section> */}
        <section id="partners" className="splash-section-6">
          <div className="row">
            <div className="col-xs-12 col-sm-6 section-text">
              <div className="text-11">
                Put your extra space to work.
              </div>
              <div className="text-12">
                Side hustles shouldn't just be for people. Create a secondary revenue stream for your business and engage with new customers.
              </div>
              <div className="row section-buttons">
                <div className="col-xs-12 col-sm-auto">
                  <ButtonComponent color="black" color-pattern="flat" size="lg" onClick={(evt) => goToAnchor(evt, "lets-go")}>Find Out More</ButtonComponent>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 section-splash-image">
              <img src={devices} alt=""></img>
            </div>
          </div>
        </section>
        <section id="businesses" className="splash-section-7">
          <div className="row">
            <div className="col-xs-12 col-sm-6 section-text">
              <div className="text-13">
                Your warehouse doesn't have to be a warehouse.
              </div>
              <div className="text-14">
                Find space for your inventory. No more astronomical warehousing fees.
              </div>
              <div className="row section-buttons">
                <div className="col-xs-12 col-sm-auto">
                  <ButtonComponent color="secondary" color-pattern="flat" size="lg" onClick={(evt) => goToAnchor(evt, "lets-go")}>Find Out More</ButtonComponent>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 section-splash-image">
              <img src={splash_network} alt=""></img>
            </div>
          </div>
        </section>
        <footer id="lets-go">
          <div className="row">
            <div className="col">
              <img className="footer-logo" src={den_logo} alt="Den"></img>
            </div>
          </div>
          <div className="row" style={{ rowGap: "3.2rem" }}>
            <div className="col-xs-12 col-sm-5">
              <div className="footer-text-1">
                Get in Touch
              </div>
              <div className="footer-text-2">
                A member of our team will be in touch shortly.
              </div>
              {!isSubmitted
                ? <form className="footer-form" onSubmit={async (e) => { e.preventDefault(); handleSubmitForm() }}>
                  <InputComponent
                    color-pattern="outline"
                    color="white"
                    label="Enter your email"
                    size="md"
                    inputId="email"
                    inputName="email"
                    value={email}
                    disabled={isSubmitting}
                    onValueChange={(value) => {
                      setEmail(value);
                      if (emailErrors) {
                        setEmailErrors();
                      }
                    }}
                    errorText={emailErrors}
                  />
                  <IconButtonComponent icon="right" type="submit" color="primary" color-pattern="flat" size="md" disabled={isSubmitting}></IconButtonComponent>
                </form>
                : <div className="footer-form-submitted">
                  Thanks for signing up! We're excited to talk soon!
                </div>
              }
            </div>
            <div className="offset-sm-5 col-xs-12 col-sm-2">
              <div className="link-item">
                <a href="#home">
                  Home
                </a>
              </div>
              <div className="link-item">
                <a href="#users">
                  For Users
                </a>
              </div>
              <div className="link-item">
                <a href="#partners">
                  For Partners
                </a>
              </div>
              <div className="link-item">
                <a href="#businesses">
                  For Business
                </a>
              </div>
              <div className="link-item">
                Get In Touch
              </div>
            </div>
          </div>
          <div className="row final-footer">
            <div className="col-xs-12 col-sm">
              <div className="footer-text-3">
                253 W 72nd Street, New York, NY USA
              </div>
              <div className="footer-text-4">
                © 2023 Den Storage Inc.&nbsp; All Rights Reserved.
              </div>
            </div>
            <div className="col-xs-12 col-sm-1">
              <a className="icon-link" href="https://www.linkedin.com/company/den-nyc" target="_blank" rel="noreferrer">
                <IconComponent value="linkedin-logo" size="md"></IconComponent>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
export default App;