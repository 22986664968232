import React, { forwardRef } from 'react';
import { BaseComponent } from '../Base/BaseComponent';
import { useComposedHandle } from '../Helpers/ComponentHelper';


const BaseControlComponent = forwardRef((props, ref) => {

  const {
    children,
  } = props;

  const parentRef = useComposedHandle(ref, {}, {
    cid: -1,
  });

  return (
    <BaseComponent {...props} ref={parentRef}>
      {children}
    </BaseComponent>
  );
})

export { BaseControlComponent };
