import React, { useState } from 'react';
import { BaseInputComponent } from '../BaseInput/BaseInputComponent';
import { composePropMap, initProps, refValue, unusedProps, useRefState } from '../Helpers/ComponentHelper';

import './InputComponent.scoped.scss';

const InputComponent = (props) => {

  const parentRef = useRefState();

  const propMap = composePropMap(parentRef, {
    inputId: "",
    inputName: "",
    type: "text",
    label: "",
    placeholder: "",
    errorText: "",
    successText: "",
    value: "",
    disabled: false,
    onValueChange: undefined,
    onSubmit: undefined,
  })
  props = initProps(props, propMap);
  const {
    inputId,
    inputName,
    type,
    label,
    placeholder,
    errorText,
    successText,
    value,
    disabled,
    onSubmit,
  } = props;

  function parent(propKey, ...args) {
    return refValue(props, parentRef, propKey, ...args) || "";
  }

  const [inlineLabel, setInlineLabel] = useState(showInlineLabel(value));

  function hasLabel() {
    return props.labelType !== "none";
  }

  function handleChange(event) {
    const value = event.target.value;
    // console.log(value);
    parent("handleChange", event);
    updateLabelClass(value);
  }

  function handleInputFocused() {
    setInlineLabel(false);
  }

  function handleInputBlurred() {
    updateLabelClass();
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      if (onSubmit) onSubmit(event.target.value, event);
    }
    // this.submit.emit(props.value);
  }

  function showInlineLabel(value) {
    return (props.labelType === "float") ? false : !value;
  }

  function updateLabelClass(currentValue) {
    setInlineLabel(showInlineLabel(currentValue || value));
  }

  function validationClass() {
    if (errorText) {
      return "invalid-input";
    } else if (successText) {
      return "success-input";
    } else {
      return "";
    }
  }

  return (
    <BaseInputComponent {...props} ref={parentRef}>
      <div className={`input-component ${parent("sizeClass")} ${parent("paletteClass")} ${parent("colorPatternClass")} ${parent("labelTypeClass")} ${parent("bottomTextClass")} ${validationClass()} ${props.className}`} {...unusedProps(parentRef, props, propMap)}>
        <div className={`input-wrapper ${!hasLabel() ? 'no-input-label' : ''}`}>
          <label htmlFor={inputId} className={`label-container ${inlineLabel ? 'inline-input-label' : ''} ${!hasLabel() ? 'no-input-label' : ''}`}>
            {label}
          </label>
          <div className="input-container">
            <input id={inputId} name={inputName} type={type} className={`palette-base ${parent("sizeClass")}`}
              placeholder={placeholder} autoComplete="off" value={value} onKeyDown={handleKeyDown}
              onChange={handleChange} onFocus={handleInputFocused} onBlur={handleInputBlurred} disabled={disabled}
            />
          </div>
          <div className={`bottom-text ${!props.showBottomText ? 'no-bottom-text' : ''}`}>
            {errorText}
          </div>
        </div>
      </div>
    </BaseInputComponent>
  );
}

export { InputComponent };
