import React, { forwardRef, useState } from 'react';
import { BaseControlComponent } from '../BaseControl/BaseControlComponent';
import { initProps, useComposedHandle } from '../Helpers/ComponentHelper';

const BaseInputComponent = forwardRef((props, ref) => {
  const propMap = {
    color: "black",
    "color-pattern": "outline",
    labelType: "auto",
    showBottomText: true,
    value: undefined,
    onValueChange: undefined,
  }
  props = initProps(props, propMap);

  const {
    value,
    children,
  } = props;

  const [_value, _setValue] = useState(value);

  function colorPatternClass(props) {
    switch (props["color-pattern"]) {
      case "outline": return "outline-pattern";
      case "fill": return "fill-pattern";
      default: return "outline-pattern";
    }
  }

  function labelTypeClass(props) {
    switch (props.labelType) {
      case "none": return "no-input-label";
      case "auto": return "auto-input-label";
      case "float": return "float-input-label";
      default: return "auto-input-label";
    }
  }

  function bottomTextClass(props) {
    return props.showBottomText ? "bottom-text-input" : "no-bottom-text-input";
  }

  function handleChange(props, event) {
    const value = event.target.value;
    // console.log(value);
    _setValue(value);
    if (props.onValueChange) {
      props.onValueChange(event.target.value, event);
    }
  }

  const parentRef = useComposedHandle(ref, propMap, {
    colorPatternClass,
    labelTypeClass,
    bottomTextClass,
    handleChange,
  });

  return (
    <BaseControlComponent {...props} ref={parentRef}>
      {children}
    </BaseControlComponent>
  );
})

export { BaseInputComponent };
