import React, { useEffect, useState } from 'react';
import { default as BaseIcons } from '../../Assets/Icons/icons-base.svg';
import { default as SolidIcons } from '../../Assets/Icons/icons-solid.svg';

import { BaseComponent } from '../Base/BaseComponent';
import { composePropMap, initProps, refValue, unusedProps, useRefState } from '../Helpers/ComponentHelper';

import './IconComponent.scoped.scss';

const IconTypes = [
  "add", "all", "backpack", "bell", "chat", "circle", "dashboard", "details",
  "directions", "down", "globe", "gps", "grid", "heart", "home", "hotel",
  "items", "left", "list", "luggage", "money", "nav", "payment", "phone",
  "profile", "reservations", "right", "search", "settings", "shirt", "star",
  "star_orange", "timer", "up", "x", "logo", "linkedin-logo", "apple-logo",
  "google-logo", "users", "partners", "businesses"
];

const IconComponent = (props) => {

  const parentRef = useRefState();

  const propMap = composePropMap(parentRef, {
    value: undefined,
    type: "base",
  })
  props = initProps(props, propMap);
  const {
    value,
    type,
  } = props;

  function parent(propKey, ...args) {
    return refValue(props, parentRef, propKey, ...args) || "";
  }

  const [link, setLink] = useState()

  useEffect(() => {
    if (IconTypes.find(icon => icon === value)) {
      setLink(`${iconFile()}#${value}`);
    } else {
      console.error(`'${value}' is not a valid icon value.`);
    }
  }, [value, type])

  function iconFile() {
    switch (type) {
      case "solid":
        return SolidIcons;
      case "base":
      default:
        return BaseIcons;
    }
  }
// if (props.value === "details") alert(JSON.stringify(parentRef.current, null, 2))
  return (
    <BaseComponent {...props} ref={parentRef}>
      <div className={`icon-component ${parent("sizeClass")} ${props.className}`} {...unusedProps(parentRef, props, propMap)}>
        <svg className={`${props.size ? props.size : ''}`}>
          <use xlinkHref={link} />
        </svg>
      </div>
    </BaseComponent>
  );
}

export { IconComponent, IconTypes };
