import React, { useEffect, useState } from 'react';

import "./Carousel.scoped.scss";

const delayFn = (fn, initialMS, intervalMS, ...args) => {
  let interval;
  const initialTimeout = setTimeout(() => {
    fn()
    interval = setInterval(() => fn(), intervalMS)
  }, initialMS)
  return () => {
    clearTimeout(initialTimeout);
    clearInterval(interval);
  }
}

const Carousel = (props) => {

  const {
    className = "",
    children,
    ...others
  } = props;

  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    let currIndex = 0;
    const clearFn = delayFn(() => {
      if (!document.hidden) {
        currIndex = (currIndex + 1) % React.Children.toArray(children).length;
        // console.log(currIndex);
        setContentIndex(currIndex);
      }
    }, 3000, 3000);
    return () => {
      clearFn();
    }
  }, []);

  return (
    <div className={`carousel ${className}`} {...others}>
      <div className="carousel-items" style={{ transform: `translateX(${contentIndex * -100}%)` }}>
        {React.Children.map(children, (child, index) => (
          <div className={`carousel-item-view-panel ${contentIndex === index ? "visible" : ""}`} style={{ transform: `translateX(${index * 100}%)` }}>
            <div className="carousel-item-container-outer">
              <div className="carousel-item-container-inner">
                {child}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;