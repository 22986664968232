import React, { forwardRef } from 'react';
import { BaseControlComponent } from '../BaseControl/BaseControlComponent';
import { initProps, useComposedHandle } from '../Helpers/ComponentHelper';

const BaseButtonComponent = forwardRef((props, ref) => {
  const propMap = {
    color: "primary",
    type: "button",
    "color-pattern": "basic",
    disabled: false,
    isToggle: false,
    selectable: false,
    selected: false,
    onSelectedChange: undefined,
  }
  props = initProps(props, propMap);

  const {
    children,
  } = props;

  function colorPatternClass(props) {
    switch (props["color-pattern"]) {
      case "basic": return "basic-pattern";
      case "raised": return "raised-pattern";
      case "stroked": return "stroked-pattern";
      case "stroked-grey": return "stroked-grey-pattern";
      case "flat": return "flat-pattern";
      case "flat-border": return "flat-border-pattern";
      default: return "basic-pattern";
    }
  }

  function handleClick(props) {
    if (!props.disabled) {
      if (props.selectable) {
        const isSelected = props.selected;
        let selected = props.selected;
        if (props.isToggle) {
          selected = !props.selected;
        } else {
          selected = true;
        }

        if (isSelected !== selected) {
          // console.log(props)
          if (props.onSelectedChange) props.onSelectedChange(selected);
        }
      }
    }
  }


  const parentRef = useComposedHandle(ref, propMap, {
    colorPatternClass,
    handleClick,
  })

  return (
    <BaseControlComponent {...props} ref={parentRef}>
      {children}
    </BaseControlComponent>
  );
})

export { BaseButtonComponent };
