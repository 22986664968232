import React from 'react';
import { BaseButtonComponent } from '../BaseButton/BaseButtonComponent';
import { composePropMap, initProps, refValue, unusedProps, useRefState } from '../Helpers/ComponentHelper';

import './ButtonComponent.scoped.scss';

const ButtonComponent = (props) => {

  const parentRef = useRefState();

  const propMap = composePropMap(parentRef, {
    
  })
  props = initProps(props, propMap);
  const {
    children,
  } = props;

  function parent(propKey, ...args) {
    return refValue(props, parentRef, propKey, ...args) || "";
  }

  return (
    <BaseButtonComponent {...props} ref={parentRef}>
      <div className={`button-component ${props.disabled ? 'disabled' : ''} ${props.className}`} {...unusedProps(parentRef, props, propMap)}>
        <button type={props.type} className={`palette-base ${parent("sizeClass")} ${parent("paletteClass")} ${parent("colorPatternClass")} ${props.selected ? 'selected' : ''}`} disabled={props.disabled} onClick={() => parent("handleClick")}>
          {children}
        </button>
      </div>
    </BaseButtonComponent>
  );
}

export { ButtonComponent };
