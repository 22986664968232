import React from 'react';
import ReactDOM from 'react-dom';

import { setupIonicReact } from '@ionic/react';
// https://capacitorjs.com/docs/web/pwa-elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import App from './View/App/App';
import reportWebVitals from './reportWebVitals';

// import { AuthProvider } from '@den/common/src/Providers/Auth';
// import { DataProvider } from '@den/common/src/Providers/Data';
// import { LoadingProvider } from '@den/common/src/Providers/Loading';
// import { UIProvider } from '@den/common/src/Providers/UI';

import './index.scss';

setupIonicReact();

ReactDOM.render(
  <React.StrictMode>
    {/* <AuthProvider>
      <DataProvider>
        <UIProvider>
          <LoadingProvider> */}
            <App />
          {/* </LoadingProvider>
        </UIProvider>
      </DataProvider>
    </AuthProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
