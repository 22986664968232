import { useEffect, useImperativeHandle, useRef, useState } from "react";

/**
 * Wrapper around `useRef(...)` that allows ref to stay initialized even when
 * the page reloads. Fixes an issue where the ref would be `null` when modifying
 * React code and saving it when using the `npm start` dev server.
 * @param {*} initialValue The initial value of the ref.
 * @returns The ref state object.
 */
function useRefState(initialValue = null) {
    const ref = useRef(initialValue);
    const [refState, setRefState] = useState(ref);

    useEffect(() => {
        if (ref.current) {
            setRefState({ ...ref });
        }
    }, [ref.current]);

    return refState;
}

function useComposedHandle(ref, propMap, values) {
    // Define parent ref
    const parentRef = useRef(null);
    // Assign function & properties to pass to children
    useImperativeHandle(ref, () => ({
        // Wrap all functions to include default props from propMap
        ...composeValues({
            // Add param values to instance map
            ...values,
            // Add parent values to instance map
            ...refValues(parentRef)
        }, propMap),
        propMap: composePropMap(parentRef, propMap)
    }));
    return parentRef;
}

function composePropMap(ref, propMap) {
    return initProps(propMap, refProp(ref, "propMap") || {});
}

function unusedProps(ref, props, propMap) {
    if (ref.current) {
        const uniqueProps = Object.fromEntries(Object.entries(props).filter(([key, val]) => key !== "children" && !Object.entries(propMap).some(([pkey, pval]) => key === pkey)));
        // console.log("UNIQUE PROPS:", uniqueProps);
        return uniqueProps;
    }
    return {};
}

function refValues(ref) {
    return ref.current || {};
}

function refValue(props, ref, propKey, ...args) {
    const current = ref.current;
    if (current) {
        const propValue = current[propKey];
        if (propValue) {
            if (typeof propValue === "function") {
                return propValue(props, ...args);
            } else {
                return propValue;
            }
        }
    }
    return undefined;
}

function refProp(ref, propKey) {
    const current = ref.current;
    if (current) {
        const propValue = current[propKey];
        if (propValue) {
            return propValue;
        }
    }
    return undefined;
}

function createPropMap(props, ref) {
    const propMap = {};
    const current = ref.current;
    if (current) {
        for (const propKey in current) {
            const propValue = current[propKey];
            if (propValue) {
                if (typeof propValue === "function") {
                    propMap[propKey] = (...args) => propValue(props, ...args);
                } else {
                    propMap[propKey] = propValue;
                }
            }
        }
    }
    return propMap;
}

function composeValues(instanceMap, propMap) {
    const newMap = {};
    for (const key in instanceMap) {
        const instanceValue = instanceMap[key];
        if (typeof instanceValue === "function") {
            newMap[key] = (props, ...args) => instanceValue(initProps(props, propMap), ...args);
        } else {
            newMap[key] = instanceValue;
        }
    }
    return newMap;
}

function initProps(props, propMap = {}) {
    return { ...propMap, ...props };
}

export {
    useRefState,
    useComposedHandle,
    composePropMap,
    unusedProps,
    refValues,
    refValue,
    refProp,
    createPropMap,
    composeValues,
    initProps,
};