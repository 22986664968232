import React from 'react';
import { BaseButtonComponent } from '../BaseButton/BaseButtonComponent';
import { composePropMap, initProps, refValue, unusedProps, useRefState } from '../Helpers/ComponentHelper';
import { IconComponent } from '../Icon/IconComponent';

import './IconButtonComponent.scoped.scss';

const IconButtonComponent = (props) => {

  const parentRef = useRefState();

  const propMap = composePropMap(parentRef, {
    icon: undefined,
    "icon-type": undefined,
    "icon-size": "",
  })
  props = initProps(props, propMap);
  const {
    icon,
    "icon-type": iconType,
    // If it doesn't exist, `icon-size` prop defaults to `size` prop
    "icon-size": iconSize = props["icon-size"] || props.size,
  } = props;

  function parent(propKey, ...args) {
    return refValue(props, parentRef, propKey, ...args) || "";
  }

  return (
    <BaseButtonComponent {...props} ref={parentRef}>
      <div className={`icon-button-component ${props.disabled ? 'disabled' : ''} ${props.className}`} {...unusedProps(parentRef, props, propMap)}>
        <button type={props.type} className={`palette-base ${parent("sizeClass")} ${parent("paletteClass")} ${parent("colorPatternClass")} ${props.selected ? 'selected' : ''}`} disabled={props.disabled} onClick={() => parent("handleClick")}>
          <div className="icon-btn">
            <div className="icon-btn-icon">
              <IconComponent value={icon} type={iconType} size={iconSize}></IconComponent>
            </div>
          </div>
        </button>
      </div>
    </BaseButtonComponent>
  );
}

export { IconButtonComponent };
